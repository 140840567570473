"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
;
exports.monthTracker = {
    years: {}
};
var enMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
var itMonths = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
];
function months(lang) {
    if (lang === 'it') {
        return itMonths;
    }
    return enMonths;
}
exports.months = months;
function indexOfMonth(month) {
    var index = enMonths.indexOf(month);
    if (index === -1) {
        index = itMonths.indexOf(month);
    }
    return index;
}
exports.indexOfMonth = indexOfMonth;
var enDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];
var itDays = [
    'Domenica',
    'Lunedi',
    'Martedi',
    'Mercoledi',
    'Giovedi',
    'Venerdi',
    'Sabato'
];
function days(lang) {
    if (lang === 'it') {
        return itDays;
    }
    return enDays;
}
exports.days = days;
function fill(arr, upto) {
    var temp = [];
    arr = temp.concat(arr);
    for (var i = 0; i < upto; i++) {
        arr[i] = undefined;
    }
    return arr;
}
// builds the calender for one month given a date
// which is end, start or in middle of the month
function scrapeMonth(date) {
    var originalDate = new Date(date.getTime());
    var year = date.getFullYear();
    var month = date.getMonth();
    var data = {
        date: originalDate,
        month: undefined
    };
    exports.monthTracker.current = new Date(date.getTime());
    exports.monthTracker.current.setDate(1);
    exports.monthTracker.years[year] = exports.monthTracker.years[year] || {};
    if (exports.monthTracker.years[year][month] !== undefined) {
        data.month = exports.monthTracker.years[year][month];
        return data;
    }
    date = new Date(date.getTime());
    date.setDate(1);
    exports.monthTracker.years[year][month] = [];
    var tracker = exports.monthTracker.years[year][month];
    var rowTracker = 0;
    while (date.getMonth() === month) {
        var _date = date.getDate();
        var day = date.getDay();
        if (_date === 1) {
            tracker[rowTracker] = fill([], day);
        }
        tracker[rowTracker] = tracker[rowTracker] || [];
        tracker[rowTracker][day] = _date;
        if (day === 6) {
            rowTracker++;
        }
        date.setDate(date.getDate() + 1);
    }
    var lastRow = 5;
    if (tracker[5] === undefined) {
        lastRow = 4;
        tracker[5] = fill([], 7);
    }
    var lastRowLength = tracker[lastRow].length;
    if (lastRowLength < 7) {
        var filled = tracker[lastRow].concat(fill([], 7 - lastRowLength));
        tracker[lastRow] = filled;
    }
    data.month = tracker;
    return data;
}
exports.scrapeMonth = scrapeMonth;
function scrapePreviousMonth() {
    var date = exports.monthTracker.current;
    if (!date) {
        throw Error('scrapePrevoisMonth called without setting monthTracker.current!');
    }
    date.setMonth(date.getMonth() - 1);
    return scrapeMonth(date);
}
exports.scrapePreviousMonth = scrapePreviousMonth;
function scrapeNextMonth() {
    var date = exports.monthTracker.current;
    if (!date) {
        throw Error('scrapePrevoisMonth called without setting monthTracker.current!');
    }
    date.setMonth(date.getMonth() + 1);
    return scrapeMonth(date);
}
exports.scrapeNextMonth = scrapeNextMonth;
var dateEndings = {
    st: [1, 21, 31],
    nd: [2, 22],
    rd: [3, 23]
};
function getDisplayDate(lang, _date) {
    var date = _date.getDate();
    if (lang === 'it') {
        return date;
    }
    if (dateEndings.st.indexOf(date) !== -1) {
        return date + 'st';
    }
    if (dateEndings.nd.indexOf(date) !== -1) {
        return date + 'nd';
    }
    if (dateEndings.rd.indexOf(date) !== -1) {
        return date + 'rd';
    }
    return date + 'th';
}
exports.getDisplayDate = getDisplayDate;
function formatTimeFromInputElement(hour) {
    var timeString = '';
    var minute = "00";
    timeString += hour < 10 ? '0' + hour : hour;
    timeString += ':' + minute;
    return timeString;
}
exports.formatTimeFromInputElement = formatTimeFromInputElement;
function formatDate(date) {
    var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}
exports.formatDate = formatDate;
